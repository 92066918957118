import type {
  ElementType,
  ReactNode,
  MouseEvent,
  RefObject,
  MouseEventHandler
} from 'react'

import { OutletProps } from 'react-router-dom'
import { UI } from '@carfluent/common'
import type { TypographyProps } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

import type { BusinessHours } from 'website/api/schedule.types'
import { ServiceType } from 'website/api/types'
import { GetPrequalifiedResponse } from 'website/api/types/financing'

export enum SupportedComponents {
  Header = 'Header',
  FooterInfo = 'FooterInfo',
  Login = 'Login',
  CarBodyStylePanel='CarBodyStylePanel',
  DrawerMenu = 'DrawerMenu',
  Carousel = 'Carousel',
  Filler = 'Filler',
  ExtendedImage = 'ExtendedImage',
  ExtendedTypography = 'ExtendedTypography',
  LazyImage = 'LazyImage',
  DynamicComponent = 'DynamicComponent',
  FiltersDrawerToggler = 'FiltersDrawerToggler',
  RouteOutlet = 'RouteOutlet',
  Socials = 'Socials',
  Schedule = 'Schedule',
  Map = 'Map',
  MainSearchSection='MainSearchSection',
  VehicleCard = 'VehicleCard',
  RecommendedVehicles = 'RecommendedVehicles',
  SearchBar = 'SearchBar',
  Button = 'Button',
  Input = 'Input',
  VehicleInfiniteScroll = 'VehicleInfiniteScroll',
  VehiclesFilter = 'VehiclesFilter',
  VehiclesFilterChips = 'VehiclesFilterChips',
  VehiclesSorting = 'VehiclesSorting',
  VehiclesSearch = 'VehiclesSearch',
  DealerInfo = 'DealerInfo',
  Text = 'Text',
  TooltipText = 'TooltipText',
  List = 'List',
  GalleryVehicleView = 'GalleryVehicleView',
  VehicleViewContent = 'VehicleViewContent',
  VehicleBriefInfo = 'VehicleBriefInfo',
  VehicleSpecsOverview = 'VehicleSpecsOverview',
  FeatureOptionList = 'FeatureOptionList',
  RequestHelpOrDrive = 'RequestHelpOrDrive',
  TextWithFiller = 'TextWithFiller',
  GetPrequalifiedComponent = 'GetPrequalifiedComponent',
  GetPrequalifiedRedirectComponent = 'GetPrequalifiedRedirectComponent',
  GetPrequalifiedForm = 'GetPrequalifiedForm',
  InfoCard = 'InfoCard',
  Modal = 'Modal',
  CarAppraisalForm = 'CarAppraisalForm',
  Banner = 'Banner',
  CustomerReviews = 'CustomerReviews',
  VinStickerButton = 'VinStickerButton',
  GetPrequalifiedSuccess = 'GetPrequalifiedSuccess',
  CapitalOnePQTS = 'CapitalOnePQTS',
  TradeInDetailsFormPage = 'TradeInDetailsFormPage',
  Condition = 'Condition',
  TradeInCar = 'TradeInCar',
  TradeInUserDetailsForm = 'TradeInUserDetailsForm',
  ZipCodeLocation = 'ZipCodeLocation',
  ZipCodeLocationModal = 'ZipCodeLocationModal',
  SsnYearIncomeForm = 'SsnYearIncomeForm',
  FeedbackForm = 'FeedbackForm',
  UnsubscribeButton = 'UnsubscribeButton',
  IncludedWithCarAds = 'IncludedWithCarAds',
  Messenger = 'Messenger'
}

export enum SupportedComponentNames {
  GeneralLayout = 'GeneralLayout',
  AboutUsPromos = 'AboutUsPromos',
  AboutUsPage = 'AboutUsPage',
  InventoryPage = 'InventoryPage',
  FeedbackPage = 'FeedbackPage',
  Footer = 'Footer',
  NotFound = 'NotFound',
  VehicleView = 'VehicleView',
  HowItWorks = 'HowItWorks',
  GetPrequalifiedCallToAction = 'GetPrequalifiedCallToAction',
  VehicleServiceRequestModalForm = 'VehicleServiceRequestModalForm',
  TradeSellIntro = 'TradeSellIntro',
  CallToActionInfo = 'CallToActionInfo',
  DealerLocationsPopover='DealerLocationsPopover',
  VehicleCardSkeleton = 'VehicleCardSkeleton',
  PrequalifiedFormSSNIncome = 'PrequalifiedFormSSNIncome',
  PrequalifiedFormError = 'PrequalifiedFormError',
  GetPrequalifiedFormPage = 'GetPrequalifiedFormPage',
  InfoLink = 'InfoLink',
  UnsubscribePage = 'UnsubscribePage',
  UnsubscribedPage = 'UnsubscribedPage',
}

export type ComponentPropsMap = {
  [SupportedComponents.Header]: HeaderProps
  [SupportedComponents.FooterInfo]: FooterInfoProps
  [SupportedComponents.ExtendedImage]: ExtendedImageProps
  [SupportedComponents.ExtendedTypography]: ExtendedTypographyProps
  [SupportedComponents.LazyImage]: LazyImageProps
  [SupportedComponents.CarBodyStylePanel]: CarBodyStylePanelProps
  [SupportedComponents.Login]: LoginProps
  [SupportedComponents.Carousel]: CarouselProps
  [SupportedComponents.Filler]: FillerProps
  [SupportedComponents.DrawerMenu]: DrawerMenuProps
  [SupportedComponents.RouteOutlet]: RouteOutletProps
  [SupportedComponents.DynamicComponent]: DynamicComponentProps
  [SupportedComponents.FiltersDrawerToggler]: FiltersDrawerTogglerProps
  [SupportedComponents.Socials]: SocialsProps
  [SupportedComponents.Schedule]: ScheduleProps
  [SupportedComponents.Map]: MapProps
  [SupportedComponents.MainSearchSection]: MainSearchSectionProps
  [SupportedComponents.VehicleCard]: VehicleCardProps
  [SupportedComponents.RecommendedVehicles]: RecommendedVehiclesProps
  [SupportedComponents.SearchBar]: SearchBarProps
  [SupportedComponents.Button]: ButtonProps
  [SupportedComponents.Input]: InputProps
  [SupportedComponents.VehicleInfiniteScroll]: VehicleInfiniteScrollProps
  [SupportedComponents.VehiclesFilter]: VehiclesFilterProps
  [SupportedComponents.VehiclesFilterChips]: VehiclesFilterChipsProps
  [SupportedComponents.VehiclesSorting]: VehiclesSortingProps
  [SupportedComponents.VehiclesSearch]: VehiclesSearchProps
  [SupportedComponents.DealerInfo]: DealerInfoProps
  [SupportedComponents.Text]: TextProps
  [SupportedComponents.TooltipText]: TooltipTextProps
  [SupportedComponents.List]: ListProps
  [SupportedComponents.VehicleViewContent]: VehicleViewContentProps
  [SupportedComponents.GalleryVehicleView]: GalleryVehicleViewProps
  [SupportedComponents.VehicleBriefInfo]: VehicleBriefInfoProps
  [SupportedComponents.IncludedWithCarAds]: IncludedWithCarAdsProps
  [SupportedComponents.VehicleSpecsOverview]: VehicleSpecsOverviewProps
  [SupportedComponents.RequestHelpOrDrive]: RequestHelpOrDriveProps
  [SupportedComponents.TextWithFiller]: TextWithFillerProps
  [SupportedComponents.GetPrequalifiedComponent]: GetPrequalifiedComponentProps
  [SupportedComponents.GetPrequalifiedRedirectComponent]: GetPrequalifiedRedirectComponentProps
  [SupportedComponents.GetPrequalifiedForm]: GetPrequalifiedFormProps
  [SupportedComponents.UnsubscribeButton]: UnsubscribeButtonProps
  [SupportedComponents.GetPrequalifiedSuccess]: GetPrequalifiedSuccessProps
  [SupportedComponents.Modal]: ModalProps
  [SupportedComponents.CarAppraisalForm]: CarAppraisalFormProps
  [SupportedComponents.Banner]: BannerProps
  [SupportedComponents.CustomerReviews]: CustomerReviewsProps
  [SupportedComponents.VinStickerButton]: VinStickerButtonProps
  [SupportedComponents.CapitalOnePQTS]: CapitalOnePQTSProps
  [SupportedComponents.TradeInDetailsFormPage]: TradeInDetailsFormPageProps
  [SupportedComponents.TradeInCar]: TradeInCarProps
  [SupportedComponents.TradeInUserDetailsForm]: TradeInUserDetailsFormProps
  [SupportedComponents.ZipCodeLocation]: ZipCodeLocationProps
  [SupportedComponents.ZipCodeLocationModal]: ZipCodeLocationModalProps
  [SupportedComponents.FeatureOptionList]: FeatureOptionListProps
  [SupportedComponents.SsnYearIncomeForm]: SsnYearIncomeFormProps
  [SupportedComponents.FeedbackForm]: FeedbackFormProps,
  [SupportedComponents.Messenger]: MessengerProps
  /**
   * FIX-ME: replace 'any' with a better type.
   * DD-NOTE: wanted to union with Record<string, DynamicComponentProps>
   * but then it complains that some component have not sufficient types
   * to be compatiable.
   */
} & Record<string, any>

export type SupportedStyledComponents = SupportedComponents | SupportedComponentNames

// #region
// ========================================== //
//           COMMON COMPONENT TYPES           //
// ========================================== //

export type Breakpoints = [number, number?]

export interface IconProps {
  fill?: string
  stroke?: string
  className?: string
  onClick?: MouseEventHandler<SVGSVGElement>
  width?: number
  height?: number
}

export type IconTypes = 'phone'
| 'map'
| 'arrowRight'
| 'arrowDown'
| 'upDownArrows'
| 'close'
| 'checked'
| 'filters'
| 'search'
| 'location'

export interface SvgIconProps extends IconProps {
  type: IconTypes
}

/**
 * Extend component props from this type if you need to create it through iteration
 */
export interface ComponentInfo {
  /**
   * might be used to override component name.
   * For example when we generate components via iteration we need to erase its relation to general layout styles
   * and therefore remove its grid-area.
   *
   * In all situations when we create components like [...].map(props => <Component {...props} />) the layout is
   * very simple and should be easily configured by the parent component
   *
   * In some other situations we might set specific grid-name and define new grid-template-area style
   *
   * To erase nameInLayout simply pass it as an empty string (this is compatiable with className type)
   */
  nameInLayout?: string

  /**
   * Will be used to distinguish between components in type-guards.
   * Optional for now, for the back compatibility.
   */
  type?: string

  /**
   * Enables access to the shared state.
   * Use `useSharedState` to get state by this ID.
   */
  states?: KeyVal

  /**
   * Used to set className from the parent
   */
  className?: string
}

export type VariantProps = Record<string, string | number | boolean>

export interface CustomizableComponent {
  /**
  * 'variant' is used to specify different variant of the component styles provided by theme
  */
  variant?: string

  /**
   * variantProps may be provided for a component variant function
   */
  variantProps?: VariantProps

  /**
   * Used to set className from the parent
   */
  className?: string
}

// #endregion

// #region
// ========================================== //
//               BASE COMPONENTS              //
// ========================================== //

// =========DealerLocationsPopoverProps====== //

export interface DealerLocationsPopoverProps extends CustomizableComponent {
  phoneConfig?: {
    icon?: IconTypes
    format?: string
  }
  addressConfig?: {
    icon?: IconTypes
  }
}

// ================ComponentRoot============= //

export interface ComponentRootProps {
  children?: ReactNode | ReactNode[]
  className?: string
  classes?: {
    root?: string
    content?: string
  }
  nameInLayout?: string
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void
}

// ==============DynamicComponent============ //

export interface DynamicComponentProps extends
  ComponentInfo, TemplateConfigs, CustomizableComponent {}

// ================FeedbackForm============= //

export interface FeedbackFormProps extends ComponentInfo {}

// =============FiltersDrawerToggler========= //

export interface FiltersDrawerTogglerProps extends ComponentInfo, CustomizableComponent {
  breakpoints?: Breakpoints
  startIcon?: SvgIconProps['type']
  text?: string
}

// ===============CarAppraisalForm=========== //
export interface CarAppraisalFormProps extends ComponentInfo, CustomizableComponent {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}

// ===================Modal================== //
export type ModalProps = UI.ModalProps & ComponentInfo & CustomizableComponent & {
  intro?: string
}

// =================ExtendedImage============ //

export interface ExtendedImageProps extends ComponentInfo {
  src?: string | null
  href?: string | null
  alt?: string | null
  isBackground?: boolean
  width?: number
  height?: number
  backgroundSize?: 'cover' | 'contain'
}

// =================LazyImage================ //
export interface LazyImageProps extends ComponentInfo {
  src?: string | null
  alt?: string | null
  lazyLoad?: boolean
  isPlaceholder?: boolean
}

// #endregion

// #region
// ========================================== //
//   SUPPORTED STATIC COMPONENTS FOR LAYOUTS  //
// ========================================== //

// ================RouteOutlet=============== //

export interface RouteOutletProps extends OutletProps, ComponentInfo, CustomizableComponent {}

// ==================Header================== //

export type HeaderComponentProps = Required<Pick<
ComponentPropsMap,
SupportedComponents.Login
| SupportedComponents.List
| SupportedComponents.DrawerMenu
| SupportedComponents.ExtendedImage
| SupportedComponents.SearchBar>>

export interface HeaderProps extends ComponentInfo, TemplateProps, CustomizableComponent, DealerInfoFragmentConfigProps {
  componentProps: HeaderComponentProps
  useDealFlowNavigation?: boolean
}

// ================FooterInfo================ //
export interface FooterInfoProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  footerImg?: string
}

// ==================Login=================== //
export interface LoginProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  buttonVariant?: string
  onClick?: () => void
  useDealFlowNavigation?: boolean
}

// ==================GetStarted============== //
export interface GetStartedProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  dealerId: string
  vehicle?: API.VehicleItem | null
}

// ==========GetPrequalifiedComponent======== //

export interface GetPrequalifiedButtonProps extends CustomizableComponent {
  text?: 'Get pre-qualified'
  onClick?: () => void
}

export interface VehicleServiceRequestFormProps {
  dealerId: string | number
  vehicleInfo?: string
  serviceType: ServiceType
  formVariant?: string
  modalVariant?: string
  vehicleId?: number
}

// ==================Messenger============== //
export interface MessengerProps extends ComponentInfo, TemplateProps, CustomizableComponent {

}

export interface VehicleServiceRequestModalFormProps extends VehicleServiceRequestFormProps {
  handleSubmit?: () => void
  onCloseModal: () => void
  isModalOpen: boolean
}

export interface GetPrequalifiedComponentProps extends ComponentInfo, CustomizableComponent {
  className?: string
  dealerId: string | number
  buttonProps: GetPrequalifiedButtonProps
  formProps?: VehicleServiceRequestFormProps
}

export interface GetPrequalifiedRedirectComponentProps extends GetPrequalifiedComponentProps {
  to: string
}

export interface GetPrequalifiedFormProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Button: ButtonProps
    TextReminder: TextProps
    TextHeadPersonalInfo: TextProps
  }
}

export interface UnsubscribeButtonProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Button: ButtonProps
  }
}

export interface SsnYearIncomeFormProps extends ComponentInfo, CustomizableComponent {

}

export interface GetPrequalifiedSuccessProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Text1: TextProps
    Text2: TextProps
    Text3: TextProps
    Text4: TextProps
    Text5: TextProps
    Text6: TextProps
    LazyImage1: LazyImageProps
    LazyImage2: LazyImageProps
  }
}

// ==========CapitalOnePQTS======== //

export interface CapitalOnePQTSProps extends ComponentInfo {
  isTextButton?: boolean
  endIcon?: string
  salePrice: string | number
  vinNumber: string | number
  mainImageUrl?: string | null
  text?: string
  onClick?: () => void
  layout?: LayoutStyles
}

// ==================Carousel================ //

export interface CarouselConfigs {
  interval?: number
  pauseOnMouseEnter?: boolean
}
export interface CarouselProps extends ComponentInfo, CustomizableComponent {
  slideProps: DynamicComponentProps[]
  configs?: CarouselConfigs
}

// ===================Text=================== //

export interface TextConfig {
  variant?: TypographyVariant
  showEllipsis?: boolean
  color?: React.CSSProperties['color']
  fontWeight?: number
  fontSize?: string
  textAlign?: string
  lineHeight?: number | string
}

export interface ExtendedTypographyProps extends TextConfig, Omit<TypographyProps, 'variant' | 'color'> {
  component?: ElementType
  isHoverable?: boolean
  className?: string
  value?: string
  nameInLayout?: string
}

export interface TextLabelConfig {
  value?: TextConfig
  label?: TextConfig
}

export const isTextConfigCommon = (config?: TextConfig | TextLabelConfig): config is TextConfig => {
  if (config == null) {
    return true
  }
  const maybeTextLabelConfig = config as TextLabelConfig
  return maybeTextLabelConfig.value == null && maybeTextLabelConfig.label == null
}

export interface TextProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  value?: string | number | null
  label?: string
  config?: TextConfig | TextLabelConfig
  icon?: string
  linkPath?: string
  format?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  rootRef?: RefObject<HTMLDivElement>
}

export interface TextWithFillerProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  value?: string | number | null
  label?: string
  config?: TextConfig | TextLabelConfig
  fillerConfig?: FillerProps
}

export interface TooltipTextProps extends TextProps {
  Tooltip: {
    Popover?: Omit<UI.PopoverProps, 'open' | 'anchorEl'>
    Text: TextProps
    maxWidth?: number
  }
}

// ===============DealerInfo================= //

export interface DealerInfoFragmentProps {
  className?: string
  phone: string | null
  addressData: {
    address: string | null
    city: string | null
    state: string | null
    zipCode: string | null
  }

  phoneConfig?: {
    icon?: SvgIconProps
    label?: string
    format?: string
  }
  addressConfig?: {
    icon?: SvgIconProps
    label?: string
  }
}

type DealerInfoFragmentConfigProps = Pick<
DealerInfoFragmentProps,
'phoneConfig' | 'addressConfig'
>

export type DealerInfoComponentProps = Required<Pick<
ComponentPropsMap,
SupportedComponents.Schedule |
SupportedComponents.Map
>>

export interface DealerInfoProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  componentProps: DealerInfoComponentProps
  multiDealershipHeader?: string
  singleDealershipHeader?: string
}

// ==================Filler================== //

export interface FillerProps extends ComponentInfo {
  color: React.CSSProperties['color']
  borderRadius?: string
  border?: string
  minWidth?: string
}

// ==============CarBodyStylePanel=========== //

export interface CarBodyStylePanelProps extends ComponentInfo, TemplateProps, CustomizableComponent {}

// ================DrawerMenu============= //

export interface DrawerMenuProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  componentProps: { List: ListProps, SearchBar: SearchBarProps }
  useDealFlowNavigation?: boolean
}

// ===================Socials================== //

export interface SocialsProps extends ComponentInfo {
  text?: string
  links?: Array<{
    src: string
    href: string
    alt: string
  }>
}

// ===================Schedule================= //

export interface ScheduleProps extends ComponentInfo {
  hours?: BusinessHours
}

// =============RecommendedVehicles============ //
export interface RecommendedVehiclesProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  componentProps: {
    Text: TextProps
    Button: ButtonProps
    VehicleCard: VehicleCardProps
  }
  maxItemsNumber?: number
}

// ===================InfoCard================= //

export interface InfoCardProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  image: string
  title: string
  subtitle?: string
  text: string
}

// ===================InventoryPage================= //

export interface InventoryPageProps extends ComponentInfo, TemplateProps {
  componentProps: DynamicComponentProps
  states?: {
    filters?: string
    sorting?: string
  }
  zipCodeLocationModalBreakpoints?: Breakpoints
}

// =================VehicleCard================ //

export interface VehicleCardProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  id: string | number
  active?: boolean
  openVehicleOnClick?: boolean
  vehicle?: API.VehicleItem
  prequalify?: GetPrequalifiedResponse<number>
  emptyImage?: string
  monthlyPaymentDetails?: API.VehicleMonthlyPayment | null
  isMonthlyPaymentLoading?: boolean
}

export interface InfoLinkProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  icon?: ReactNode
  title: string
  content: string
  actionTitle: string
  onClick?: () => void
}

// ========InfinityVehicleCardsListProps======== //

export interface VehicleInfiniteScrollProps extends ComponentInfo, TemplateProps {
  componentProps: { VehicleCard: VehicleCardProps, NoVehiclesView: NoVehiclesViewProps }
  states: {
    filters: string
    sorting: string
  }
}

// ===============NoVehiclesView============= //

export interface NoVehiclesViewProps {
  imgSrc: string
  text?: string
}

// ===============VehiclesFilter=============== //

export enum FiltersPanelComponents {
  ZipCodeInput = 'ZipCodeInput',
  VehiclesSorting = 'VehiclesSorting',
  FilterPanel = 'FilterPanel'
}

export interface VehiclesFilterProps extends ComponentInfo, CustomizableComponent {
  title?: string
  drawerBreakpoints: Breakpoints
  sections: Array<{
    label?: string
    componentName: FiltersPanelComponents
    props?: {
      variant?: string
    }
  }>
  states: {
    filters: string
    sorting: string
  }
}

// ===============ZipCodeLocation============== //

export interface ZipCodeLocationProps extends TemplateConfigs, CustomizableComponent {
  breakpoints?: Breakpoints
}

// ============ZipCodeLocationModal============ //

export interface ZipCodeLocationModalProps extends TemplateConfigs, CustomizableComponent {
  isOpen?: boolean
  breakpoints?: Breakpoints
  onClose?: () => void
}

// =============VehiclesFilterChips============= //

export interface VehiclesFilterChipsProps extends ComponentInfo {
  states: {
    filters: string
  }
}

export interface VehiclesSearchProps extends TemplateConfigs, CustomizableComponent {
  states: {
    filters: string
  }
}

// ===============VehiclesSortingProps=============== //

export interface VehiclesSortingProps extends ComponentInfo, CustomizableComponent {
  states: {
    sorting: string
  }
  popoverClassName?: string
  startAdornmentIcon?: SvgIconProps['type']
  label?: string
  mode?: 'select' | 'search'
}

// =====================List==================== //

/**
 * As List renders Dynamics it is obligatory for a list item to have nameInTheLayout
 * so that the inner div would have full width and height
 *
 * DD-TODO: think how to improve this, because initially List did not need to have nameInTheLayout at all
 */

export interface ListProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  /**
   * by default it would be 'id'
   * then it would move to listeItem nameInTheLayout
   * and after that to index
   */
  itemKey?: string
  /**
   * specifies item renderer. DynamicComponent is by default
   */
  itemType?: string

  items?: Array<Partial<ComponentPropsMap> & {type?: SupportedComponents}>
  /**
   * lists very often are populated after fetch, so we need soma shared config for a listItem
   */
  itemComponentProps?: Partial<ComponentPropsMap>
  listName?: string
  listNameConfig?: TextConfig
}

// ===================SearchBar================ //

/**
 * DD-TODO: fix SearchBarProps type.
 * We can not pass in config any actions. But SearchBar by itself needs these functions to pass further.
 *
 * That is why onClick and onChange are optional for now
 */
export interface SearchBarComponentProps {
  [SupportedComponents.Button]: ButtonProps
  [SupportedComponents.Input]: InputProps
}

export interface SearchBarProps extends Omit<ComponentInfo, 'states'>, TemplateProps, CustomizableComponent {
  componentProps: SearchBarComponentProps
  onSearch?: (val: string) => Promise<void>
  btnText?: string
  showSearchIcon?: boolean
  label?: string
  placeholder?: string
  shouldResetOnSearch?: boolean
  shouldShowClearButton?: boolean
}

// ===============MainSearchSection============= //

export interface MainSearchSectionProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  componentProps: {
    SearchBar: SearchBarProps
    Text1: TextProps
    Text2: TextProps
  }
}

// ====================Button================== //

export interface ButtonProps extends ComponentInfo, CustomizableComponent {
  text: string
  onClick?: (evt?: MouseEvent) => void // DD-NOTE: temporary optional
  dataTestId?: string
  disabled?: boolean
  isLoading?: boolean
  buttonVariant?: string
  buttonClassName?: string
  type?: 'button' | 'reset' | 'submit' | 'icon'
  navTo?: string
  navAction?: 'replace' | 'push'
  startIcon?: string
  endIcon?: string
}

// ====================Input=================== //

export interface InputProps extends ComponentInfo, CustomizableComponent, Omit<UI.FormCompatible<UI.InputProps, UI.InputValue>, 'id' | 'value'> {
  /**
   * we also set input's name by its id
   */
  id?: string
  value?: string | number
}

// =====================Map==================== //

export interface MapProps extends ComponentInfo {
  addressData: {
    address: string | null
    city: string | null
    state: string | null
    zipCode: string | null
  }
  destination?: string | null
}
// =====================VehicleViewContent==================== //
export interface VehicleViewContentComponentsProps {
  GalleryVehicleView: ComponentPropsMap[SupportedComponents.GalleryVehicleView]
  VehicleBriefInfo: ComponentPropsMap[SupportedComponents.VehicleBriefInfo]
  VehicleSpecsOverview: ComponentPropsMap[SupportedComponents.VehicleSpecsOverview]
  IncludedWithCarAds: TemplateConfigs
  Text: ComponentPropsMap[SupportedComponents.Text]
  FeatureOptionList: ComponentPropsMap[SupportedComponents.FeatureOptionList]
  Text1: ComponentPropsMap[SupportedComponents.Text]
  InfoLinkBlock: { nameInLayout: string }
  TestDriveBlock: { nameInLayout: string }
}

export interface VehicleViewContentProps extends ComponentInfo, TemplateProps, CustomizableComponent, TemplateConfigs {
  id: string | number
  componentProps: VehicleViewContentComponentsProps
  noPhotoImage?: string
}

export interface GalleryVehicleViewProps extends TemplateConfigs, CustomizableComponent {
  images: API.ImageData[]
  media: API.VehicleMediaData | null
  emptyListImage?: string
  altTextForMainImage?: string
  noPhotoClassName?: string
}

// =====================VehicleBriefInfo==================== //
export interface VehicleBriefInfoComponentsProps {
  ExploreFinancingButton?: ComponentPropsMap[SupportedComponents.Button]
}

export interface VehicleBriefInfoProps extends ComponentInfo, CustomizableComponent {
  vehicle?: API.VehicleItem | null
  carfaxPartnerCode: string
  componentProps?: VehicleBriefInfoComponentsProps
  onClick: () => void
}

export interface CarAdTextFieldsProps {
  Text1: string
  Text2: string
}

export interface IncludedWithCarAdsProps extends ComponentInfo, TemplateConfigs, CustomizableComponent {
  settings?: API.Settings | null
  carAdTextFieldsProps?: CarAdTextFieldsProps | null
}

export interface IncludedWithCarAdsMapperProps {
  key: string
  component?: string
  settings?: API.Settings | null
  propsMap?: Partial<ComponentPropsMap>
  carAdTextFieldsProps?: CarAdTextFieldsProps | null
}

// =====================VehicleDescription==================== //

export interface VehicleDescriptionProps extends ComponentInfo, CustomizableComponent {
  description: string | null
}

// =====================VehicleDealershipInfo==================== //

export interface VehicleDealershipInfoProps extends ComponentInfo {
  dealership: API.DealerInfo | null
}

// =====================VehicleSpecsOverview==================== //
export interface VehicleSpecsOverviewComponentsProps {
  Text: ComponentPropsMap[SupportedComponents.Text]
  Title: ComponentPropsMap[SupportedComponents.Text]
  VinStickerButton?: ComponentPropsMap[SupportedComponents.VinStickerButton]
}

export interface VehicleSpecsOverviewProps extends TemplateProps, ComponentInfo, CustomizableComponent {
  componentProps: VehicleSpecsOverviewComponentsProps
  vehicle?: API.VehicleItem | null
  odometerIcon: string
  exteriorColorIcon: string
  interiorColorIcon: string
  engineIcon: string
}

export interface FeatureOptionItem {
  name: string
  options: string[]
}

export interface FeatureOptionListProps extends TemplateProps, ComponentInfo, CustomizableComponent {
  height?: number
  options?: FeatureOptionItem[]
  className?: string
}

// ==========================RequestHelpOrDrive========================= //
export interface RequestHelpOrDriveProps extends
  ComponentInfo, CustomizableComponent, VehicleServiceRequestFormProps {}

// ================================Banner=============================== //
export interface BannerProps extends ComponentInfo {
  Text: {
    value: string
    config?: TextConfig
  }
  Image: ComponentPropsMap[SupportedComponents.ExtendedImage]
  Filler?: ComponentPropsMap[SupportedComponents.Filler]
}

// ===========================CustomerReviews=========================== //
export interface CustomerReviewsProps extends ComponentInfo {
  Text: {
    value: string
    config?: TextConfig
  }
}

// #endregion

// ==========================VinStickerButton=========================== //
export interface VinStickerButtonProps extends ComponentInfo {
  vin: string
  text?: string
}

// #endregion

// ===========================TradeIn=========================== //
export interface TradeInDetailsFormPageProps extends ComponentInfo {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}
export interface TradeInCarProps extends ComponentInfo, CustomizableComponent {
  isTitleExist?: boolean
  isMobileTitleHidden?: boolean
  isPriceBarExist?: boolean
  isActionBarExist?: boolean
  isSearchCarExist?: boolean
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
    dealership: string
  }
}

export interface TradeInUserDetailsFormProps extends ComponentInfo, CustomizableComponent {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
    dealership: string
  }
}

export interface ConditionSubOptionProps {
  title: string
  value: number
  isChecked?: boolean
}

export interface ConditionOptionProps {
  className?: string
  title: string
  value: number
  subOptions?: ConditionSubOptionProps[]
  isActive?: boolean
  onClick?: (id: number, options?: ConditionSubOptionProps[]) => void
  description?: string
}

export interface ConditionData {
  value: number | null
  subOptionValue: number[] | null
}

export interface ConditionProps extends ComponentInfo, CustomizableComponent {
  subOptionsType?: 'checkbox' | 'radio'
  id: string
  title: string
  options: ConditionOptionProps[]
  onChange?: (id: string, value: ConditionData) => void
  value?: ConditionData | null
  error?: unknown | string | null
  touched?: unknown | boolean | null
}

// #endregion

// #region
// ========================================== //
//              TEMPLATE TYPES                //
// ========================================== //

export interface FlexTemplate {
  name: string
  props?: Record<string, string>
  fullOffset?: boolean
}
export interface TemplateProps {
  /**
   * 'template' is used for layout templates written on the client side
   * DD-TODO:
   * add more specific types when work with templates gets more standart
   */
  template?: FlexTemplate
  /**
   * css grid layout for different breakpoints
   */
  layout?: LayoutStyles
}

export interface TemplateConfigs extends ComponentInfo, TemplateProps {
  /**
   * for grid layout order of components in componentProps is not important as it puts each one into cells
   * for flex layout order is very important as it puts components one after another into the fluid flex layout
   */
  componentProps?: Partial<ComponentPropsMap>
}
// #endregion

// #region
// ========================================== //
//              STYLE UTILITY TYPES           //
// ========================================== //

export const extendedTypographyVariants = [
  'default',
  'text1',
  'text2',
  'textMain',
  'textInfo',
  'header1',
  'header4',
  'h4Thin',
  'header1Thin',
  'subtitle1Thin',
  'smallText',
  'noCarInfoTitle'
] as const

export type ExtendedTypographyVariant = typeof extendedTypographyVariants[number]
export type TypographyVariant = Variant | ExtendedTypographyVariant

export const componentThemeStyleProps = ['root', 'content'] as const
export type ComponentThemeStyleProps = typeof componentThemeStyleProps[number]

interface EllipsisConfig {
  showEllipsis: boolean
}

interface HoverableConfig {
  isHoverable: boolean
}

interface VariantConfig {
  variant?: TypographyVariant
}

interface TextStyleConfig {
  color?: string
  fontWeight?: number
  lineHeight?: number | string
}

export interface TypographyConfig extends EllipsisConfig, HoverableConfig, VariantConfig, TextStyleConfig {}

export type ComponentVariant = string | 'default'
export interface TypographyCss {
  typography: Partial<{
    [t in TypographyVariant]: string
  }>
}

export interface ButtonTextConfig {
  color?: string
  textAlign?: 'left' | 'right' | 'center'
  textDecorationStyle?: 'underline' | 'overline' | 'line-through' | 'none'
  fontWeight?: number
}

export interface HeaderDealFlowConfig {
  isAuthPage?: boolean
  isOutsideFlowPage?: boolean
  isYourDetailsPage?: boolean
  isPaymentSharePage?: boolean
}

export type ComponentThemeCssStyles = Record<ComponentVariant, Partial<{
  [prop in ComponentThemeStyleProps]: string | ((props: VariantProps) => string)
}>>

export type ComponentThemeStyles = Partial<{
  [prop in ComponentThemeStyleProps]: string
}>

export type ComponentStylesFromTheme = Record<ComponentThemeStyleProps, string>

export type ThemeSupportedStyles = Partial<Record<SupportedStyledComponents, ComponentThemeCssStyles>>

export type CssTheme = TypographyCss & ThemeSupportedStyles & { link: Record<string, string> }

export type StyleDefinition<T> = T | { [key: string]: StyleDefinition<T> }

export interface GridStyles {
  display: 'grid'
  'grid-area'?: string
  'grid-column-gap': string
  'grid-row-gap': string
  'grid-template-rows': string
  'grid-template-areas': string
  'grid-template-columns': string
  'justify-content': string
  'align-content': string
  'align-items': string
  'justify-items': string
  'align-self': string
  'justify-self': string
  margin: string
  padding: string
}

export type LayoutStyleWithBreakpoint = GridStyles & {
  breakpoint?: number
}

export type LayoutStyles = LayoutStyleWithBreakpoint[]

export type VisibilityStyle = Record<`& > .${string}`, { display: 'none' | 'block' }>
& Record<`& > div > .${string}`, { display: 'none' | 'block' }>

export type InfiniteRowConfig = Record<number | string, number>

/**
 * Record 'string' key is for mediaQuery.
 * Value contains css grid config as well as nested visibility rules for components specified in the layout.
 * These visiblity rules are added to avoid listening to resize inside JS code
 *
 * THE LAST '& Record<string, any>' is added for compatibility with CreateCSSProperties type
 */
export interface ResponsiveGridStyle {
  [x: `@media (min-width: ${number}px)`]: Partial<GridStyles> & Partial<VisibilityStyle> & Record<string, any>
}
// #endregion
