import ctaImg1 from 'website/assets/happy-coop.png'
import ctaImg2 from 'website/assets/woman-enjoying-her-financially-independence-while-buying-car.png'

const assets: Record<string, string> = {
  ctaImg1,
  ctaImg2,
  block1Text1: ' takes a radically different approach and eliminates time consuming procedures embedded in traditional transaction processes. In addition, the consumer demands complete transparency throughout the entire buying process and prefers immediate access to the quality of the vehicle, the repairs and service performed on the automobile, and potential upcoming maintenance in the near term.',
  block2Text1: 'Want to Talk About Finance?',
  block2Text2: 'Due to the most efficient and streamlined operations, DriveSimple is able to pass on the savings to consumers.',
  block2Text3: "Traditional dealerships will NOT be able to compete on price even after negotiating due to DriveSimple's streamlined operations, lower operating costs and virtual dealership model. While price is not the only differentiator, price is the number one factor in a consumer's decision-making process."
}

export default assets
